// jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;


// https://www.npmjs.com/package/slick-carousel
require('../../assets/library/slick-carousel/slick/slick.min.js');

// https://github.com/bartholomej/material-scrollTop
require('material-scrolltop');
$('body').materialScrollTop();

// https://www.npmjs.com/package/@fancyapps/fancybox
require('@fancyapps/fancybox');

// Scripts
require('./modules/sliders');
require('./modules/scripts');













