// Sliders
function initSlick() {
  var $slider = $('.c-slider-home__main');

  if ($slider.hasClass('slick-initialized')) {
    $slider.slick('unslick');
  }

  var slickSettings = {
    dots: false,
    infinite: true,
    speed: 1200,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 6800,
    pauseOnHover: false,
    arrows: true,
    slide: '.c-slider-home__item'
  };

  if ($(window).width() <= 768) {
    // slickSettings.adaptiveHeight = true;
  }

  $slider.slick(slickSettings);
}

initSlick();

$(window).on('resize', function() {
  // initSlick();
});




$('.c-carousel__container').slick({
  dots: false,
  infinite: true,
  autoplay: true,
  pauseOnHover: true,
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 4,

  slide: '.c-carousel__item',
  responsive: [
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    }
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    }
  }
  ]

});





$('.c-gallery__portfolio-item-slider').on('init', function(event, slick, currentSlide){
    var nrCurrentSlide = slick.currentSlide + 1, // din cauza ca e array si incepe de la 0
    totalSlidesPerPage = nrCurrentSlide + 3; // daca ai 5 thumb-uri pe pagina pui + 4
    $('.c-gallery__controls').html(nrCurrentSlide + " - " + totalSlidesPerPage + " de " + slick.slideCount);
  });

$('.c-gallery__portfolio-thumb-slider').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.c-gallery__portfolio-item-slider',
  dots: false,
  arrows: true,
  focusOnSelect: true,
  infinite: false,
  responsive: [
  {
    breakpoint: 760,
    settings: {
      arrows: false 
    }
  }
  ]
});

$('.c-gallery__portfolio-item-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  asNavFor: '.c-gallery__portfolio-thumb-slider',
  infinite: false,
  adaptiveHeight: true,
  arrows: true
});

var current = 0;
$('.c-gallery__portfolio-thumb-slider .slick-slide:not(.slick-cloned)').eq(current).addClass('slick-current');
$('.c-gallery__portfolio-item-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
  current = currentSlide;
  var nrCurrentSlide = slick.currentSlide + 1,
  totalSlidesPerPage = nrCurrentSlide + 3; 

  if(totalSlidesPerPage > slick.slideCount) {
    $('.c-gallery__controls').html(nrCurrentSlide + " - " + slick.slideCount + " de " + slick.slideCount);
  } else {
    $('.c-gallery__controls').html(nrCurrentSlide + " - " + totalSlidesPerPage + " de " + slick.slideCount);
  }
});

