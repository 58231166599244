console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);



// links gallery
$('a[data-gallery-target]').on('click', function(e) {
  e.preventDefault();
  var targetGallery = $(this).data('gallery-target');
  $(targetGallery).find('a').first().click();
});



// Menu lateral
$('#header .js-eventClick-open-child').click(function(){
  if ($('#body').hasClass('is-active-menu')){
    $(this).toggleClass('is-open');
    $(this).siblings().removeClass('is-open');
  }
});
$(document).keyup(function(e) {
  if ($('#body').hasClass('is-active-menu')) {
    if (e.keyCode === 27) { $('#js-header__menu-mobile').click();}
  }
});
$('#js-body__header__menu-mobile').click(function(){
  $('#js-header__menu-mobile').click();
});

$('#js-header__menu-mobile').click(function(){
  $('#body').toggleClass('is-active-menu');
  $(this).toggleClass('is-active');
  $('.header__layer').toggleClass('is-active');
  if (!$('.header__layer').hasClass('is-active')) {
    $('.header__layer').addClass('no-active');
  } else {
    $('.header__layer').removeClass('no-active');
  }
});


